<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">New Category</h6>
      </b-row>
    </b-card>
    <div>
      <b-form ref="form">
        <!-- Row Loop -->
        <div class="pb-2">
          <b-card>
            <b-row>
              <b-col md="8">
                <b-row>
                  <!-- Category Name -->
                  <b-col md="4">
                    <b-form-group label="Category Name" label-for="category-name">
                      <b-form-input id="category-name" type="text" v-model="category.title" placeholder="Category Name" />
                    </b-form-group>
                  </b-col>
                  <!-- Category Code -->
                  <b-col md="4">
                    <b-form-group label="Category Meta Title" label-for="category-meta-title">
                      <b-form-input id="category-meta-title" type="text" v-model="category.meta_title" placeholder="Category Meta Title" />
                    </b-form-group>
                  </b-col>

                  <b-col md="4" v-if="category.settings">
                    <b-form-group label="Category Type" label-for="category-type" class="mb-2">
                      <select id="category-type" v-model="category.settings.type" class="custom-select" @change="changeTypeArr()">
                        <option v-for="(cat, index) in categoryTypeOptions" :key="'category-type-' + index" :value="cat.value">
                          {{ cat.text }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <!-- Category Description -->
                  <b-col md="12">
                    <b-form-group label="Category Description" label-for="category-description">
                      <b-form-textarea id="category-meta-title" type="text" v-model="category.content" placeholder="Category Description" />
                    </b-form-group>
                  </b-col>
                  <!-- Category Image -->
                  <b-col md="12">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Featured Image</h4>
                      <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                        <b-media-aside v-if="category.image">
                          <b-img ref="refPreviewEl" :src="category.image" height="110" width="170" class="rounded mr-2 mb-1 mb-md-0" />
                        </b-media-aside>
                        <b-media-body>
                          <div class="d-inline-block">

                            <input type="file" accept=".jpg, .png" size="300000" placeholder="Choose file" ref="refInputEl" @input="inputImageRenderer" @change="toDataURL"></input>
                          </div>
                        </b-media-body>
                      </b-media>
                    </div>
                  </b-col>
                </b-row>
                <template v-if="category.settings != null && category.settings.type == 1">
                  <br/><br/>
                  <b-row>
                  <b-col md="4">
                    <b-form-group label="Hide Category If Closed" label-for="category-hide" class="mb-2">
                      <select id="category-hide" v-model="category.settings.hide" class="custom-select">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
                  <b-row v-for="(day, dayIndex) in weekDays" :key="'option-' + day + dayIndex">
                    <b-col md="3">
                      <b-form-group :label="day" :label-for="'show-' + day" class="mb-2">
                        <b-form-select :id="'show-' + day" v-model="category.settings[day].ticked" :options="openDays" :style="'flex-flow: row !important; flex-wrap: wrap !important;'"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group :label="day + ' start'" :label-for="'open-' + day" class="mb-2">
                        <b-time locale="en" :id="'open-' + day" v-model="category.settings[day].from" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Hide From" :label-for="'close-' + day" class="mb-2">
                        <b-time locale="en" :id="'close-' + day" v-model="category.settings[day].to" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                </template>
              </b-col>
              <b-col md="4">
                <b-form-group :label="'Assigned Products'" label-for="products-products_tagged" class="producttag"></b-form-group>
                <v-select id="products-products_tagged" v-model="category.products_arr" multiple :options="productsOptions" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveData()">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>
              </b-col>
              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" block to="/takeaway/products/categories">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Close</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BTime,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BCard,
  BFormTextarea,
  BImg,
  BMediaAside,
  BMedia,
  BFormFile,
  BMediaBody,
  BLink,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

const aws = require('aws-sdk');
import vSelect from "vue-select";
aws.config.update({
  accessKeyId: 'AKIARLZ53ZXES6XSB6UR',
  secretAccessKey: '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'
});

export const s3 = new aws.S3({
  signatureVersion: 'v4',
  region: 'eu-west-1'
});
import axios from "axios"
export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BImg,
    BMediaAside,
    BMedia,
    BMediaBody,
    BFormFile,
    vSelect,
    BTime,
    BFormTextarea,
    BLink,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: ["id"],
  data() {
    return {
      products_raw: [],
      productsOptions: [],
      products_arr: [],
      category: {
        title: '',
        settings: {},
        meta_title: '',
        image: '',
      },
      categoryTypeOptions: [
        { value: 0, text: "Simple" },
        { value: 1, text: "Advanced" }
      ],
      openDays: [
        { value: 0, text: "Closed" },
        { value: 1, text: "Open" }
      ],
      weekDays: ['monday', 'tuesday', 'wednessday', 'thursday', 'friday', 'saturday', 'sunday']
    };
  },
  methods: {

    changeTypeArr() {
      if (this.category.settings === null) this.category.settings = []

      for (let x = 0; x < this.weekDays.length; x++) {
        this.category.settings[this.weekDays[x]] = {}
        this.category.settings[this.weekDays[x]] = { ticked: 1, from: '00:00', to: '23:59' }
      }

      this.$forceUpdate()
    },
    async toDataURL() {
      let file = this.$refs.refInputEl.files[0];
      let key = 'tenant-' + JSON.parse(localStorage.getItem('userData')).tenant_id + '/categories/' + Date.now() + '-' + file.name;

      const params = {
        Bucket: 'tenants-app',
        Key: key,
        Expires: 10,
        ContentType: file.type
      };

      const url = s3.getSignedUrl('putObject', params);
      return axios
        .put(url, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        .then(result => {
          const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0];

          this.category.image = bucketUrl + key;

          return result;
        })
        .catch(err => {
          // TODO: error handling
          console.log(err);
        });
    },
    async saveData() {
      let allCats = JSON.parse(localStorage.getItem('userCategories'));
      if (JSON.parse(localStorage.getItem('userCategories')).length) {
        allCats = allCats.length
      } else {
        allCats = 0;
      }
      await useJwt
        .post_category('create', {
          image: this.category.image,
          title: this.category.title,
          meta_title: this.category.meta_title,
          slug: this.category.title.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, ""),
          content: this.category.content,
          position: allCats,
          settings: JSON.stringify(this.category.settings)
        }).then((response) => {
          let new_id = response.data.data.id
          useJwt
            .list_categories()
            .then(async () => {
              localStorage.setItem(
                "userCategories",
                JSON.stringify(response.data.data)
              );

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Category saved`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully saved category.`,
                },
              });
              if(this.category.products_arr){
              if (this.category.products_arr.length) {

                for (let x = 0; x < this.category.products_arr.length; x++) {

                  await useJwt
                    .post_product_categories('create', {
                      category_id: new_id,
                      product_id: this.category.products_arr[x].value,
                    })
                    .then(() => {
                      this.$forceUpdate();
                    })
                }
              }}
              this.$forceUpdate();
              return this.$router.push('/takeaway/products/categories')
            })
            .catch((e) => {
              console.log(e)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't downlad your products data from the server.`,
                },
              });
            });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't update category data to the server.`,
            },
          });
        });

    },
  },
  async beforeCreate() {
    await useJwt
      .list_products()
      .then((response) => {
        localStorage.setItem(
          "userProducts",
          JSON.stringify(response.data.data)
        );
        this.products_raw = response.data.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your categories data from the server.`,
          },
        });
      });

    await useJwt
      .list_categories()
      .then((response) => {
        localStorage.removeItem(
          "userCategories",
        )
        localStorage.setItem(
          "userCategories",
          JSON.stringify(response.data.data)
        );

      })
      .catch((e) => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    this.category.settings = {};
    this.category.settings.type = 0;

    for (let x = 0; x < this.weekDays.length; x++) {
      this.category.settings[this.weekDays[x]] = {}
      this.category.settings[this.weekDays[x]] = { ticked: 1, from: '00:00', to: '23:59' }
    }
    let category_temp;

    for (let y = 0; y < this.products_raw.length; y++) {

      this.productsOptions.push({
        value: this.products_raw[y].id,
        label: '(#' + this.products_raw[y].id + ') ' + this.products_raw[y].title
      });
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>